import React, { useState, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Activities from './Activities/Activities';
import Nav from './Nav/Nav';
import Home from './Home/Home';
import Teams from './Teams/Teams';
import Profile from './Profile/Profile';
import Leaderboards from './Leaderboards/Leaderboards';
import History from './Profile/History/History';
import TitleBar from '../../components/TitleBar/TitleBar';
import { AuthContext } from '../../Context/AuthContext';
import NotFound from '../../components/NotFound/NotFound';
import Aux from '../../HOC/AuxAuth/AuxAuth';
import Share from '../../components/Share/Share';
import './Layout.css';
import Preferences from './Preferences/Preferences';

const Layout2 = (props) => {
    const [totalPoints, setTotalPoints] = useState(0);
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [loadingText, setLoadingText] = useState("Loading...")
    const { authenticated, setAuthenticated, profile } = useContext(AuthContext);
    return (
        //TODO: clean up redirect, rename Aux. Remove render ifs from routes.
        <Aux authenticated={authenticated} setAuthenticated={setAuthenticated}>
            {/* <TitleBar title={profile.company ? profile.company : "Wellbeing Challenge"} /> */}
            <div className="Layout">
                <Switch>

                    <Route path="/" exact render={(props) => (authenticated ? <Home loadingText={loadingText} setLoadingText={setLoadingText} share={() => setShowShareDialog(true)} totalPoints={totalPoints} route={props} /> : <Redirect to="/login" />)} />
                    <Route path="/activities" render={(props) => (authenticated ? <Activities share={() => setShowShareDialog(true)} route={props} setTotalPoints={setTotalPoints} /> : <Redirect to="/login" />)} />
                    <Route path="/team" render={(props) => (authenticated ? <Teams share={() => setShowShareDialog(true)} route={props} /> : <Redirect to="/login" />)} />
                    <Route path="/profile/history" render={(props) => (authenticated ? <History share={() => setShowShareDialog(true)} route={props} /> : <Redirect to="/login" />)} />
                    <Route path="/profile" render={(props) => (authenticated ? <Profile share={() => setShowShareDialog(true)} route={props} /> : <Redirect to="/login" />)} />
                    <Route path="/leaderboards" render={(props) => (authenticated ? <Leaderboards share={() => setShowShareDialog(true)} route={props} /> : <Redirect to="/login" />)} />
                    <Route path="/preferences" exact render={(props) => (authenticated ? <Preferences loadingText={loadingText} setLoadingText={setLoadingText} share={() => setShowShareDialog(true)} route={props} /> : <Redirect to="/login" />)} />
                    <NotFound />
                </Switch>
            </div>
            <Nav />
                    <Share show={showShareDialog} hide={() => setShowShareDialog(false)} profile={profile}/>
                    
        </Aux>
    )
}

export default Layout2;