import React, { useContext, useEffect, useState } from 'react';
import './Home.css';
import { AuthContext } from '../../../Context/AuthContext';
import { Chart } from 'primereact/chart';
import TitleBar from '../../../components/TitleBar/TitleBar';
import superApi from '../../../Api/Api';
import Loader from '../../../HOC/Loader/Loader';

const Home = (props) => {

    const [polarPoints, setPolarPoints] = useState(null);
    const [polarData, setPolarData] = useState([0, 0, 0, 0, 0])
    const [topPeople, setTopPeople] = useState([]);

    const polarOptions = {
        title: {
            display: true,
            text: 'Your Wellbeing',
            fontSize: 24
        },
        legend: {
            position: 'bottom'
        }
    };



    const buildPolar = (points) => {
        const polarColors = Object.keys(auth.categories).map(cat => {
            return auth.categories[cat].color
        })
        const polarLabels = Object.keys(auth.categories).map(cat => {
            return auth.categories[cat].slogan.toUpperCase()
        })
        let data = {
            datasets: [{
                data: polarData,
                backgroundColor: polarColors,
                label: 'My Points'
            }],
            labels: polarLabels.slice(0, 5)
        }


        return data;
    }


    const auth = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {

        superApi.get('leaderboard/top/person')
            .then(
                (response) => {
                    setTopPeople(response.data)
                }
            ).catch(error => {
                if (error.request) {
                    props.setLoadingText(error.message+" :(")
                    
                }
            })
        superApi.get('/leaderboard')
            .then(
                (response) => {
                    setPolarPoints(response.data);

                    setIsLoading(false)
                }
            ).then(() => {
            })



    }, [])

    useEffect(() => {
        if (auth.categories) {
            if (polarPoints) {

                let _polarData = Object.keys(auth.categories).map(cat => {
                    return polarPoints[cat]
                })

                setPolarData(_polarData)
            }
        }



    }, [polarPoints, auth.categories])


    let points = 0;
    let myTeamPoints = 0;
    let achievablePoints = 0;
    let teamMemberLength = 1;

    if (auth.profile.achievements) {

        auth.profile.achievements.map((achievement) => {
            points += achievement.approved_points;
            return null;
        })



    }

    let myPointsBadge = null

    switch (points < 1000) {
        case true:
            myPointsBadge = <h1>{points}</h1>
            break;
        case false:
            myPointsBadge = <h2>{points}</h2>
            break;

    }


    if (auth.myTeam !== null) {
        auth.myTeam.members.map((member) => {
            if (member.profile.achievements) {
                const memberProfile = auth.calculatePoints(member.profile);
                memberProfile.achievements.map((achievement) => {

                    myTeamPoints += achievement.approved_points;
                    return null;
                });
            }

            return null;
        })

        teamMemberLength = auth.myTeam.members.length;

    }


    const myPoints = {
        datasets: [
            {
                data: [points, achievablePoints - points],
                backgroundColor: [
                    "#FC854E",
                    "#E9E9E9"

                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB"
                ]
            }
        ]
    }

    const teamPoints = {
        // labels: ['POINTS', 'REMAINING'],
        datasets: [
            {
                data: [myTeamPoints, (achievablePoints * teamMemberLength) - myTeamPoints],
                backgroundColor: [
                    "#FE5A99",
                    "#E9E9E9"

                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB"
                ]
            }
        ]
    }


    if (auth.profile.email) {
        return (
            <Loader text={props.loadingText} loading={isLoading}>
                <TitleBar share={props.share} items={[]} />
                <div className="Home">


                    <div className="p-grid p-nogutter top-panel">
                        <div className="p-col-6 top-panel-left">

                            {myPointsBadge}
                            <p>You</p>
                        </div>
                        <div className="p-col-6 top-panel-right">

                            <h1>{myTeamPoints}</h1>
                            <p>Team</p>
                        </div>
                        <div className="main">
                            <div className="polar">{auth.categories ? <Chart height="320px" width="320px" type="polarArea" options={polarOptions} data={buildPolar(polarPoints)} /> : null}</div>
                            <div className="topPeople">
                                <h2>Leaderboard</h2>
                                <ul>{topPeople.map((person, i) => <li key={i}>
                                    <span className="avatar-thumb">
                                        {person.avatar ? <img src={superApi.defaults.baseURL + "avatar/" + person.avatar} /> :
                                            <i className="material-icons">person</i>}
                                    </span>{person.name}<span className="points">{person.points}</span></li>)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Loader>
        );
    } else {
        return <Loader text={props.loadingText} loading={isLoading} />
    }

}

export default Home;