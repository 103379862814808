import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useContext, useEffect, useState } from 'react';
import superApi from '../../../Api/Api';
import TitleBar from '../../../components/TitleBar/TitleBar';
import { AuthContext } from '../../../Context/AuthContext';
import Leaderboard from './Leaderboard/Leaderboard';
import './Leaderboards.css';

const Leaderboards = (props) => {
    const auth = useContext(AuthContext);
    const [leaderboard, setLeaderboard] = useState({});
    const [loading, setLoading] = useState(true);
    const [type, setType] = useState('team');
    const [week, setWeek] = useState(0);
    useEffect(() => {
        if (auth.profile.slug != undefined) {
            superApi.get(`/api/leaderboard/${auth.profile.slug}`).then((response) => {
                const _lb = response.data[0]['leaderboard'];

                try {
                    setLeaderboard(_lb);
                    setLoading(false);
                } catch (e) {
                    setLoading(false);
                }
            });
        }
    }, [auth.profile]);

    function selectTeam() {
        setType('team');
    }
    function selectIndividual() {
        setType('individual');
    }
    function selectWeek() {
        const _weeks = Object.keys(leaderboard).map((key) => parseInt(key));
        const _lastweek = _weeks.reduce((a, b) => Math.max(a, b));
        setWeek(_lastweek);
    }
    function selectTotal() {
        setWeek(0);
    }

    let CONTENT = loading ? (
        <ProgressSpinner className="noLeaderboards" />
    ) : (
        <h4 className="noLeaderboards">"No Leaderboards yet!"</h4>
    );

    if (leaderboard && leaderboard[0]) {
        CONTENT = (
            <div className="board">
                <div className="tab">
                    <button
                        className={type === 'team' ? 'active' : ''}
                        onClick={selectTeam}
                    >
                        Team
                    </button>
                    <button
                        className={type === 'individual' ? 'active' : ''}
                        onClick={selectIndividual}
                    >
                        Individual
                    </button>
                </div>

                <div className="tab tab2">
                    <button
                        className={week === 0 ? '' : 'active'}
                        onClick={selectWeek}
                    >
                        Week
                    </button>
                    <button
                        className={week === 0 ? 'active' : ''}
                        onClick={selectTotal}
                    >
                        Total
                    </button>
                </div>

                <Leaderboard
                    profile={auth.profile}
                    type={type}
                    week={week}
                    leaderboards={leaderboard}
                />
            </div>
        );
    }

    return (
        <React.Fragment>
            <TitleBar share={props.share} items={[]} />
            <div className="Leaderboards">
                <h2 className="leaderboard-text">Leaderboard</h2>
                {CONTENT}
            </div>
        </React.Fragment>
    );
};

export default Leaderboards;
