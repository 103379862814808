import React, {useState} from 'react';
import './App.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/nova-colored/theme.css';
import { Route} from 'react-router-dom';
import {BrowserRouter, Switch, Redirect } from 'react-router-dom';
import Register from './containers/Auth/Register/Register';
import RegisterRedirect from './containers/Auth/RegisterRedirect/RegisterRedirect';
import Layout2 from './containers/Layout/Layout2';
import AuthContext from './Context/AuthContext';
import Login from './containers/Auth/Login/Login';
import superApi from './Api/Api';

const App = (props) => {

    
  const [userValue, setUserValue] = useState('');
  const [passValue, setPassValue] = useState('');
  const [pass2Value, setPass2Value] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  
  
  const setUserValueHandler = (val) => {
        
    setUserValue(val);
};

const setPassValueHandler = (val) => {
        
  setPassValue(val);
};

const setPass2ValueHandler = (val) => {
        
  setPass2Value(val);
};

const disableButton = () => {
    setCanSubmit(false);
};
const enableButton = () => {
    setCanSubmit(true);
};

const registerSubmit = (model, auth) => {
    superApi.post('/register', model)
    .then((response) => {
        // receives token
        window.localStorage.setItem('auth', true)
        window.localStorage.setItem('authBody', response.data.token)
        auth.setAuthenticated(true);
        auth.setAuthBody(response.data.token);
        setIsRegistered(true);

    })
    .catch((error) => {

        alert('Error');
    })
};

  const authForm = {
    userValue: userValue,
    passValue: passValue,
    pass2Value: pass2Value,
    canSubmit: canSubmit,
    setUserValueHandler: setUserValueHandler,
    setPassValueHandler: setPassValueHandler,
    setPass2ValueHandler: setPass2ValueHandler,
    disableButton: disableButton,
    enableButton: enableButton,
    registerSubmit: registerSubmit
  }

  
    
    return (
      <AuthContext>
      <div className="App">
      
            {/* <LoginInput loginHandler={this.props.loginHandler} /> */}
         
        <BrowserRouter>
        <Switch>
          <Route path="/login" exact render={(props) => <Login {...props} auth={authForm}/>}/>
          <Route path="/register" exact component={RegisterRedirect} />
          <Route path="/register/:company" render={(props) => <Register {...props} isRegistered={isRegistered} setIsRegistered={setIsRegistered} auth={authForm}/>} /> 
          
            <Route path="/" component={Layout2} />
          
        </Switch>                 
        </BrowserRouter>
        
      </div>
      </AuthContext>
      
    );
  }


export default App;
