import React, { useState } from 'react';
import {Button} from 'primereact/button';
import {Panel} from 'primereact/panel';
import './Team.css';
import superApi from '../../../../Api/Api';

const Team = (props) => {

    const [finished, setFinished] = useState(true)
    const MEMBERS = props.team.members.map(member => <li className="member">{member}</li>)
    
    if(finished) {
        return (
            <div className="Team">
                <Panel className="teamPanel" header={props.team.name} toggleable collapsed>
                    <ul>{MEMBERS}</ul>
                    <Button className="p-button-info" label={props.label} onClick={(e) => props.click(props.team._id)}/>
                </Panel>
            </div>
        )

    } else {
        return 'Loading...'
    }

}
 
export default Team;