import React, { useContext, useRef, useEffect } from 'react';
import './TitleBar.css';
import { AuthContext } from '../../Context/AuthContext';
import { Button } from 'primereact/button';
import { Redirect } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { Link } from 'react-router-dom';

const TitleBar = (props) => {
    const auth = useContext(AuthContext);
    const redirectHandler = to => <Redirect to="/leaderboards" />

    const menuRef = useRef(null)
    let items = []
    if (props.items) {

        items = [
            {
                label: 'Misc',
                items: [
                    {
                        label: 'Leaderboard',
                        icon: 'pi pi-fw pi-table',
                        url: `/leaderboards`
                    },
                    {
                        label: 'Invite a friend',
                        icon: 'pi pi-fw pi-share-alt',
                        command: () => props.share ? props.share() : console.error('passe moi les propriétés')
                    }
                ]
            },

            {
                label: 'Account',
                items: [
                    
                    {
                        label: 'Preferences',
                        icon: 'pi pi-fw pi-cog',
                        url: '/preferences'
                    },
                    {
                        label: 'Logout',
                        icon: 'pi pi-fw pi-power-off',
                        command: () => auth.logoutHandler()
                    }
                    
                ]

            },
        ]

        props.items.map((item) => {
            if (props.from == 'profile') {
                items[1].items.push(item)
                return null;
            } else {

                items[0].items.push(item);
                return null;
            }
        })
    }

    return (
        <div className="TitleBar">
            {props.title || "The Wellbeing Challenge"}
            {props.items ? <button
                className="menu"
                onClick={(e) => menuRef.current.toggle(e)}
            >
                <i className="material-icons">
                    menu
                    </i>
            </button> : ""}
            {props.items ? <Menu model={items} popup={true} ref={el => menuRef.current = el} /> : ""}
        </div>
    )


}

export default TitleBar;