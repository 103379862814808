import React, { useEffect } from 'react';
import './Activity.css';
import moment from 'moment';
import ActivityHistory from '../ActivityHistory/ActivityHistory';

const Activity = (props) => {
	let todaysPoints = 0;

	const today = props.today;
	const isChallenge = props.activity.type === 'challenge';
	const isDaily = props.activity.type === 'daily';

	switch(props.activity.type) {
		case 'challenge':
			if(props.stats) {
				props.stats.days.map((day) => {
					const d = new Date(day.day.$date);
					if (moment(day.day.$date).subtract(props.offset, 'minutes').isSame(moment(today), 'day')) {
						todaysPoints = 1;
					}
		
				})
			}

			return (
				<li className={props.stats ? "Activity challenge complete" : "Activity challenge"}>
					<div className="text">
	
						<span className="description">{props.activity.title}<i style={{ color: 'orange' }} className="pi pi-star"></i><a onClick={() => props.showHelp(props.activity.description)}><i className="material-icons help">chat</i></a></span>
						{todaysPoints ? <span className="challengeCompleteText">Well Done!</span> : <span className="action"><button className="promptLink" onClick={(e) => props.completeChallenge(props.activity)}><span>What did you do?</span><i className="material-icons">edit</i></button>
						</span>}
					</div>
	
					<div className="points">
						<span>{todaysPoints ? <a onClick={() => props.showHistory(props.activity)}><i className="material-icons">check</i></a> : <a onClick={() => props.showHistory(props.activity)}><i className="material-icons">hourglass_empty</i></a>}</span>
					</div>
	
	
				</li>
			)
			break
		case 'daily':
				if(props.stats) {
					props.stats.days.map((day) => {
		
		
						const d = new Date(day.day.$date);
						if (moment(day.day.$date).subtract(props.offset, 'minutes').isSame(moment(today), 'day')) { //TODO: use local timezone
							todaysPoints = props.activity.points;
						}
			
					})
				}
				return (
					<li className={props.stats ? "Activity daily complete" : "Activity daily"}>
						<div className="text">
							<span className="description">{props.activity.title}{props.activity.type == 'challenge' ? <i style={{ color: 'orange' }} className="pi pi-star"></i> : <a onClick={() => props.showHelp(props.activity.description)}><i className="material-icons help">chat</i></a>}</span>
							{todaysPoints ? <span className="challengeCompleteText">Well Done!</span> : <span className="action"><button className="promptLink" onClick={(e) => props.completeChallenge(props.activity)}><span>What did you do?</span><i className="material-icons">edit</i></button>
							</span>}
						</div>
		
						<div className="points">
							{/* <a onClick={() => props.showHistory(props.activity)}>{todaysPoints}</a> */}
							<span>{todaysPoints ? <a onClick={() => props.showHistory(props.activity)}><i className="material-icons">check_box</i></a> : <a onClick={(e) => props.completeChallenge(props.activity)}><i className="material-icons">check_box_outline_blank</i></a>}</span>
						</div>
					</li>
				)
			break
		default:
			if(props.stats) {
				props.stats.days.map((day) => {
	
	
					const d = new Date(day.day.$date);
					if (moment(day.day.$date).subtract(props.offset, 'minutes').isSame(moment(today), 'day')) { //TODO: use local timezone
						todaysPoints += day.points;
					}
		
				})
			}
			return (
				<li className={props.stats ? "Activity complete" : "Activity"}>
					<div className="text">
						<span className="description">{props.activity.title}{props.activity.type == 'challenge' ? <i style={{ color: 'orange' }} className="pi pi-star"></i> : <a onClick={() => props.showHelp(props.activity.description)}><i className="material-icons help">chat</i></a>}</span>
						<span className="action"><button className="promptLink" onClick={(e) => props.completeChallenge(props.activity)}><span>What did you do?</span><i className="material-icons">edit</i></button></span>
					</div>
	
					<div className="points">
						<a onClick={() => props.showHistory(props.activity)}>{todaysPoints}</a>
					</div>
				</li>
			)
			break
	}
	

}

export default Activity;