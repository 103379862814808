import React from 'react';
import './Leaderboard.css';
import superApi from '../../../../Api/Api';

const Leaderboard = (props) => {
    let leaderboard = [];
    const week_leaderboard = props.leaderboards[props.week];

    if (week_leaderboard) {
        switch (props.type) {
            case 'team':
                leaderboard = week_leaderboard.team.all;
                break;
            case 'individual':
                leaderboard = week_leaderboard.all;
        }
    }

    leaderboard.sort((r1, r2) => {
        return r2.points - r1.points;
    });

    let maxPoints = 0;
    leaderboard.forEach((row) => {
        if (row.points > maxPoints) maxPoints = row.points;
    });

    const ENTRIES = leaderboard.map((row) => {
        const avatar = row.avatar ? (
            <img src={superApi.defaults.baseURL + 'api/avatar/' + row.avatar} />
        ) : (
            <i className="material-icons">
                {props.captain ? 'perm_identity' : 'person'}
            </i>
        );

        return (
            <div key={JSON.stringify(row)} style={{ position: 'relative' }}>
                <div className="entry">
                    <div className="cell avatar">{avatar}</div>
                    <div className="cell name">{row.name}</div>
                    <div className="cell points">{row.points}</div>
                </div>
                <div className="empty-progressbar" style={{ width: `80%` }} />
                <div
                    className="progressbar"
                    style={{ width: `${(row.points / maxPoints) * 80}%` }}
                />
            </div>
        )
    })
    return (
        <div className="Leaderboard">
            {ENTRIES}
        </div>

    );
}

export default Leaderboard;