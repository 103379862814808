import React from 'react';
import { NavLink } from 'react-router-dom';
import './Nav.css';

const Nav = (props) => {

    return (
        <nav>
          <ul>
            <li><NavLink to='/'><i className="material-icons">home</i><span>Home</span></NavLink></li>
            <li><NavLink to='/activities'><i className="material-icons">event_note</i><span>Activities</span></NavLink></li>
            <li><NavLink to='/team'><i className="material-icons">group</i><span>Team</span></NavLink></li>
            <li><NavLink to='/profile'><i className="material-icons">person</i><span>Profile</span></NavLink></li>
          </ul>
        </nav>
    )
}

export default Nav;