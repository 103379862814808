import React, { useEffect, useState } from 'react';

import './CategoryHeader.css';
import superApi from '../../../../Api/Api';
const logos = require.context('../../../../assets/images', true);

const CategoryHeader = (props) => {
    // const [color, setColor] = useState("white");
    let img = null
        
        try {
            img = logos(`./${props.category}.png`)
        } catch {

            
        }
        return ( 
            <div style={{backgroundColor: props.categories[props.category].color || "black"}} className="CategoryHeader">
                <img className="catImage" src={img} />
                <span>{props.categories[props.category].slogan}</span>
                <span className="points">
                    POINTS: {props.points}
                </span>
            </div>
         );
    } 

 
export default CategoryHeader;