import React, { useEffect, useRef } from 'react';
import './ActivityHistory.css';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import superApi from '../../../../Api/Api';

const ActivityHistory = (props) => {

    const items = []
    let displayClass = 'hide';
    let noteRef = useRef(null)
    let prefix = (<i class="material-icons">
                    star_border
                </i>
                )
    
    let HISTORY = props.history.map((item, index) => {
        if (item != null) {
            
            return item.notes.map((note, i) => {
                if (item.photos != null) {
                    if (item.photos[i] != false && item.photos[i] != undefined) {
                        const img = superApi.defaults.baseURL + 'photos/' + item.photos[i]
                        prefix = (<a href={img}>Image</a>)
                    } else {
                        prefix = (<i class="material-icons">
                                    star_border
                                </i>
                                )
                    }
                }
                
                return (

                    <li className="note" key={i}>
                        <span className="header">
                            {prefix}
                        </span>
                        <span className="text">{note}</span><a onClick={(e) => props.click(e.target.previousSibling.innerText, item.day, i)} className="p-button-info">edit</a>
                    </li>

                )
            })
        }


    })

    if (props.show) {

        displayClass = 'show'
    }

    return (
        <React.Fragment>

            <div className={"ActivityHistory " + displayClass}>
                <TitleBar />
                <span className="title">
                    <a className="back" onClick={props.close}>
                        <i className="material-icons">
                            keyboard_arrow_down

                            </i>
                    </a>


                </span>
                <div className="ActivityTitle">{props.activity ? props.activity.title : ""}</div>
                <ul className="notes">
                    {HISTORY}
                </ul>
            </div>
        </React.Fragment>
    );
}




export default ActivityHistory;