import React, { useEffect, useState, useContext } from 'react';
import superApi from '../../../Api/Api';
import Activity from './Activity/Activity';
import { AuthContext } from '../../../Context/AuthContext';
import moment from 'moment';

import './Activities.css';
import AchievementDialog from './AchievementDialog/AchievementDialog';
import CategoryHeader from './CategoryHeader/CategoryHeader';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import ActivityHistory from './ActivityHistory/ActivityHistory';
import Loader from '../../../HOC/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Activities = (props) => {

    const [showActivityDialog, setShowActivityDialog] = useState(false);
    const [showEditActivityDialog, setShowEditActivityDialog] = useState(false);
    const [currentActivity, setCurrentActivity] = useState(null);
    const [achievementValue, setAchievementValue] = useState('');
    const [activityDescription, setActivityDescription] = useState(null);
    const [showActivityHelp, setShowActivityHelp] = useState(false);
    const [showActivityHistory, setShowActivityHistory] = useState(false);
    const [activityHistory, setActivityHistory] = useState([]);
    const [achievementEditDate, setAchievementEditDate] = useState(moment())
    const [achievementIndex, setAchievementIndex] = useState(null)
    const auth = useContext(AuthContext);

    const [canPrev, setCanPrev] = useState(true);
    const [canNext, setCanNext] = useState(true);
    const [viewDate, setViewDate] = useState(moment());
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const today = moment()
        const startDate = auth.profile.startdate ? moment(auth.profile.startdate.$date) : today

        if (viewDate.isSame(today, 'day')) {
            setCanNext(false);
        } else {
            setCanNext(true);
        }
        const daysDiff = today.diff(startDate, 'days')
        const subDays = daysDiff % 7;

        if (auth.profile.startdate && viewDate.isSame(moment(today).subtract(subDays, 'days'), 'day')) {
            setCanPrev(false)
        } else {
            setCanPrev(true)
        }
    })

    const completeChallengeHandler = (challenge) => {
        let _confirm = true
        if (!viewDate.isSame(moment(), 'day')) {
            _confirm = window.confirm('This is not today\s date!')
        }
        if (_confirm) {
            // Clear field value
            setAchievementValue('');
            setShowActivityDialog(true);
            setCurrentActivity(challenge);
            if (auth.profile.preferences.enable_auto_fill) {

                auth.profile.achievements.forEach(task => {
                    if (task.task.$oid == challenge._id) {
                        setAchievementValue(task.days.slice(-1)[0].notes.slice(-1)[0]) // show last entry
                    }
                });
            }
        }
    }

    const updateAchievementHandler = () => {

        superApi.patch('/achievements', {
            day: achievementEditDate,
            note: achievementValue,
            index: achievementIndex,
            task: currentActivity._id
        })
            .then((response) => {
                setShowEditActivityDialog(false);
                closeActivityHistoryHandler();
            })

    }

    const submitAchievementHandler = (e, today, photo = false) => {
        e.preventDefault();

        setIsLoading(true)
        const payload = {
            task: currentActivity._id,
            day: today.toJSON(),
            notes: achievementValue,
            photo: photo

        }

        superApi.post('/achievements', payload)
            .then((response) => {
                setShowActivityDialog(false);
                setAchievementValue("");
                auth.getProfile();
                auth.getMyTeam();
                setIsLoading(false)
                if (response.data.streak && response.data.streak > 1) {
                    toast.success("YAY " + response.data.streak + " in a row!")
                    setShowActivityDialog(false);
                    setAchievementValue("");
                } else {
                    toast.success("Well Done!")
                }
                if (response.data.bonus) {
                    toast("BONUS POINTS! +" + response.data.bonus)

                }
            })
            .catch((error) => {
                if (error.response) {
                    toast.error("Error, please refresh window")
                } else if (error.request) {
                    toast.error("Error, please try again")
                } else {
                    toast.error(error.message)
                }
                setShowActivityDialog(false);
                setAchievementValue("");
                setIsLoading(false)
            })
    }

    const setAchievementValueHandler = (value) => {
        setAchievementValue(value);
    }

    const prevDayHandler = () => {

        let newDate = moment(viewDate).subtract(1, 'days');
        setViewDate(newDate);


    }

    const showActivityHelpHandler = (helpText) => {
        setShowActivityHelp(true);
        setActivityDescription(helpText);
    }

    const nextDayHandler = () => {

        let newDate = moment(viewDate).add(1, 'days');
        setViewDate(newDate);
    }
    const showEditActivityDialogHandler = (note, _date, _index) => {
        setAchievementValue(note)
        setAchievementEditDate(_date)
        setAchievementIndex(_index)
        setShowEditActivityDialog(true)
    }
    const showActivityHistoryHandler = (activity) => {
        setCurrentActivity(activity); //TODO: Regression testing
        setShowActivityDialog(false);
        setShowActivityHistory(true);

        superApi.get('/achievements', { params: { "date": viewDate, "task": activity._id, "tzoffset": viewDate.utcOffset() } })
            .then((response) => {
                setActivityHistory(response.data._items);
            })
    }

    const closeActivityHistoryHandler = () => {
        setShowActivityHistory(false)
    }

    let CATEGORIES = {}

    const getActivites = () => {
        auth.activities.map((activity) => {

            if (auth.categories[activity.category] == null) {
                console.error(`Category ${activity.category} not defined (clear your cache?)`)
            }

            CATEGORIES[auth.categories[activity.category]['name']] ? CATEGORIES[auth.categories[activity.category]['name']].push(activity) : CATEGORIES[auth.categories[activity.category]['name']] = [activity];
            return null;

        })

        const catArray = Object.entries(CATEGORIES)
        const ACTIVITIES = catArray.map((cat, i) => {
            const category = cat[0];
            const activities = cat[1];



            let categoryPoints = 0;

            const sortByChallenge = (a, b) => {
                if (a.type == 'challenge') {
                    return -1;
                }
                else {
                    return 1;
                }
            }

            activities.sort(sortByChallenge);

            const activityElements = activities.map((activity) => {
                let stats = null;
                if (auth.profile.achievements) {
                    auth.profile.achievements.map((achievement) => {
                        if (achievement.task.$oid === activity._id) {
                            stats = achievement;
                            categoryPoints += achievement.approved_points;
                        }

                        return null;

                    })
                }
                return <Activity stats={stats}
                    offset={auth.profile.tz_offset}
                    key={activity.title}
                    activity={activity}
                    today={viewDate}
                    completeChallenge={completeChallengeHandler}
                    showChallenge={() => console.log('show Challenge')}
                    buttonClick={completeChallengeHandler}
                    showHelp={showActivityHelpHandler}
                    showHistory={showActivityHistoryHandler}
                />
            });

            const header = <CategoryHeader category={category} categories={auth.categories} points={categoryPoints} />

            return (
                <React.Fragment key={i}>
                    {header}
                    {activityElements}
                </React.Fragment>
            )
        });

        return ACTIVITIES;
    }

    const isToday = viewDate.isSame(moment(), 'day');
    return (
        <Loader text="Loading..." loading={isLoading}>
            <div className="Activities">
                <ToastContainer position="top-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover />
                <Dialog header="More Info" visible={showActivityHelp} modal onHide={() => setShowActivityHelp(false)}>
                    {activityDescription}
                </Dialog>
                <div className="dateSelect-wrapper">
                    <Button disabled={!canPrev} icon="pi pi-chevron-left" onClick={prevDayHandler} />
                    <div className={isToday ? "isToday" : ""}>{viewDate && !isToday ? viewDate.format('dd D MMM') : isToday ? "Today" : "Loading..."}</div>
                    <Button disabled={!canNext} icon="pi pi-chevron-right" onClick={nextDayHandler} />
                </div>
                <ul style={{ listStyle: 'none', padding: '0', marginBottom: '80px' }}>
                    {auth.activities != null && auth.categories != null ? getActivites() : <p>Loading...</p>}
                </ul>
                <AchievementDialog currentActivity={currentActivity}
                    setValue={setAchievementValueHandler}
                    submitAchievementHandler={submitAchievementHandler}
                    showActivityDialog={showActivityDialog}
                    setShowActivityDialog={setShowActivityDialog}
                    achievementValue={achievementValue}
                    showHistory={showActivityHistoryHandler}
                    today={viewDate}
                    type="add"
                    setIsLoading={setIsLoading}
                    enablePhotoUploads={auth.profile.preferences ? auth.profile.preferences.enable_photo_upload : "FALSE"}
                />

                <AchievementDialog currentActivity={currentActivity}
                    setValue={setAchievementValueHandler}
                    submitAchievementHandler={updateAchievementHandler}
                    showActivityDialog={showEditActivityDialog}
                    setShowActivityDialog={setShowEditActivityDialog}
                    achievementValue={achievementValue}
                    today={achievementEditDate}
                    type="edit"
                    setIsLoading={setIsLoading}
                />

                <ActivityHistory activity={currentActivity}
                    show={showActivityHistory}
                    close={closeActivityHistoryHandler}
                    history={activityHistory}
                    click={showEditActivityDialogHandler}
                    setNote={setAchievementValue}
                />


            </div>
        </Loader>
    )
}

export default Activities;