import React, { useEffect, useState } from 'react';
import './RegisterRedirect.css';
import { InputText } from 'primereact/inputtext';


const RegisterRedirect = (props) => {
    const [slugFieldValue, setSlugFieldValue] = useState(null)
    useEffect(() => {
        console.log(slugFieldValue);
    }, [slugFieldValue])

    return (
        <div className="RegisterRedirect">
            <InputText id="slug" name="slug" placeholder="Enter Slug" onChange={e => setSlugFieldValue(e.target.value)}/>
            <button className="green" onClick={() => window.location.href = `${window.location.href}/${document.getElementById('slug').value}`} >Go</button>
        </div>
    );
}

export default RegisterRedirect;