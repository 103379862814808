import React,{useRef} from 'react';
import './AchievementDialog.css';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const AchievementDialog = (props) => {
    
    const textInput = useRef(null);
    
    const doFocus = (e) => {
        if(textInput.current != null) {
            window.setTimeout(() => textInput.current.focus(),50); // A bit rude
        } else {
            console.log(textInput);
        }
    }

    
    
    const photoHook = (e) => {
        if(props.type == 'add' && props.enablePhotoUploads) {
            props.setShowActivityDialog(false)
            confirmAlert({
                title: 'Photo upload',
                message: 'Would you like to upload a photo?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => document.getElementById("photo-select").click()
                    },
                    {
                        label: 'No Thanks',
                        onClick: () => props.submitAchievementHandler(e, props.today)
                    }
                ]
            })
        } else {
            props.submitAchievementHandler(e, props.today)
        }
        
    }

    const footer = (
        <div className="footer">

            <Button onClick={(e) => props.setShowActivityDialog(false)} 
                    style={{marginLeft: '5px'}} 
                    label="cancel"
                    icon="pi pi-times"
                    className="p-button-danger"
                    />
            <Button onClick={(e) => props.showHistory(props.currentActivity)} 
                    style={{marginLeft: '5px'}} 
                    label="edit"
                    icon="pi pi-pencil"
                    className="p-button-warning"
                    />
            <Button onClick={(e) => photoHook(e, props.today)} 
                    style={{marginLeft: '5px'}} 
                    label="save"
                    icon="pi pi-check"
                    className="p-button-info"
                    />
            
        </div>

    )

    const detectEnter = (e) => {
        const code = e.which || e.keyCode;
        if (code === 13) {
            props.submitAchievementHandler(e, props.today);
        }

    }

    const uploadAvatarHandler = (e) => {
        // blob = window.URL.createObjectURL(file);
        let file = e.target.files[0]
        var reader = new FileReader();
        reader.onloadend = function() {
            props.submitAchievementHandler(e, props.today, reader.result);
            props.setIsLoading(false)
        }
        reader.readAsDataURL(file);
        props.setIsLoading(true)
    
    }

    return ( 
        <Dialog className="AchievementDialog" style={{width: '90%'}} 
                header={props.currentActivity ? props.currentActivity.title : "Loading.."} 
                footer={footer}
                visible={props.showActivityDialog} 
                modal
                onHide={() => props.setShowActivityDialog(false)}
                onShow={(e) => doFocus(e)}
                >
                <input type="text" autoFocus="autoFocus" ref={textInput} value={props.achievementValue} onKeyPress={detectEnter} onChange={(e) => props.setValue(e.target.value)} placeholder="What did you do?" />
                
               
                
                <input name="photo" id="photo-select" type="file" style={{display: 'none'}} onChange={(e) => uploadAvatarHandler(e)} />
                    
                
        </Dialog>
     );
}
 
export default AchievementDialog;