import React from 'react';
import './AnimatedLogo.css';
import renderHTML from 'react-render-html';

const AnimatedLogo = (props) => {

return (

 
  <div className="AnimatedLogo">
      {/* <div className="svg-wrapper">
      <svg id="shadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 347.76 23.19">
        <title>logo</title>
        <ellipse id="Ellipse1" cx={39} cy="11.6" rx={39} ry="11.6" style={{fill: '#f2f2f2'}} />
      </svg>
      </div> */}
      <div className="svg-wrapper">
      <svg id="_5_ways_to_wellbeing_challenge" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 945.05 430.96">
        <title>TheWellbeingChallenge2</title>
        <g style={{isolation: 'isolate'}}>
          <g id="logo">
            <g data-name="5 ways to wellbeing  challenge">
              <g>
                <g id="_1" data-name={1}>
                  <path d="M494.77,313.3V262.91h-9V251.78h33v11.13h-8.94V313.3Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M524.61,313.3V251.78h15v24.34h7.68V251.78h15.07V313.3H547.32V286.92h-7.68V313.3Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M571.66,313.3V251.78h28.8v10.09H586.73V275.6H597v10.46H586.73v17.18h13.88V313.3Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <rect x="314.31" y="129.27" width="122.71" height={7} style={{fill: '#6d6e71'}} />
                  <rect x="38.14" y="129.27" width="122.71" height={7} style={{fill: '#6d6e71'}} />
                </g>
                <g id="_2" data-name={2}>
                  <path d="M320.3,408.79l-8.8-82.41h17.1l3.78,44.74,4.77-44.74h13.22l5.22,44.29,3.88-44.29h16.95l-8.9,82.41H349.43l-5.72-46.53-5.22,46.53Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M385,408.79V326.37h38.57v13.52H405.15v18.39h13.72v14H405.15v23h18.59v13.47Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M431.75,408.79V326.37h20.18v68.94h18.34v13.47Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M478.17,408.79V326.37h20.18v68.94h18.34v13.47Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M524.6,408.79V326.37h22.32q3.28,0,5.92.17a46.35,46.35,0,0,1,5.62.75,26.15,26.15,0,0,1,5.22,1.52,19.45,19.45,0,0,1,4.37,2.61,14.17,14.17,0,0,1,3.48,3.9,21.76,21.76,0,0,1,2.09,5.57,27.42,27.42,0,0,1,.7,6.41v.94q-.3,13.87-12.08,16.3A14.52,14.52,0,0,1,573,370.24q3.8,5,4,13.79v.75q0,12.38-6.56,18.09-6.76,5.92-19.44,5.92Zm20.18-49.86h2.68q5.47,0,7.36-2.51t1.89-7.63a9.44,9.44,0,0,0-2.34-6.54,8,8,0,0,0-6.26-2.61h-3.33Zm0,35.79h2.73q5.47,0,7.75-2.73t2.29-8.3q0-6.66-2.19-9.27t-7.75-2.61h-2.83Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M586.43,408.79V326.37H625v13.52H606.61v18.39h13.72v14H606.61v23H625.2v13.47Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M633.26,408.79V326.37h20.13v82.41Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M665.37,408.79V326.37h14.32l15.06,35.29V326.37h16.65v82.41H697.63l-15-38.77v38.77Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M745.44,410.08a26.31,26.31,0,0,1-11-2.09,16.59,16.59,0,0,1-7.16-6,27.39,27.39,0,0,1-3.75-8.95,51.39,51.39,0,0,1-1.17-11.58V353.71a47.38,47.38,0,0,1,1.32-11.81,25.08,25.08,0,0,1,4.22-8.95,18.11,18.11,0,0,1,7.93-5.87,32.44,32.44,0,0,1,12.08-2,36.52,36.52,0,0,1,9.92,1.22,21.5,21.5,0,0,1,7.08,3.31,17,17,0,0,1,4.57,5.19,22.54,22.54,0,0,1,2.54,6.64,37.82,37.82,0,0,1,.75,7.8v5.92H752.95v-7.9q0-1.39,0-2.26a16.39,16.39,0,0,0-.3-2.14,5.67,5.67,0,0,0-.72-2,4.13,4.13,0,0,0-1.42-1.27,4.49,4.49,0,0,0-2.29-.55,4.74,4.74,0,0,0-2.73.75,4.08,4.08,0,0,0-1.57,2.11,12.58,12.58,0,0,0-.62,2.58,23.81,23.81,0,0,0-.15,2.86v40.41a15.38,15.38,0,0,0,.25,2.86,11.91,11.91,0,0,0,.82,2.61,4.69,4.69,0,0,0,1.74,2.06,5,5,0,0,0,2.81.77,4.87,4.87,0,0,0,3.55-1.39,6.88,6.88,0,0,0,1.89-3.16,14.67,14.67,0,0,0,.47-3.8V377H748V365.39h24.75v43.39H759.31l-1-6Q754.69,410.08,745.44,410.08Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                </g>
                <g id="_3" data-name={3}>
                  <path d="M331.39,505.56a34.59,34.59,0,0,1-12.05-1.86,17.67,17.67,0,0,1-7.9-5.54,23.45,23.45,0,0,1-4.22-8.6A45.1,45.1,0,0,1,305.89,478V448.2a45.33,45.33,0,0,1,1.32-11.63,23.46,23.46,0,0,1,4.22-8.6,17.68,17.68,0,0,1,7.9-5.54,34.59,34.59,0,0,1,12.05-1.86,37.7,37.7,0,0,1,9.89,1.17,20.83,20.83,0,0,1,7,3.23,16.54,16.54,0,0,1,4.5,5.12,22.29,22.29,0,0,1,2.46,6.54,38.38,38.38,0,0,1,.72,7.75v7.41H336.16v-9q0-1.79-.1-2.86a13.25,13.25,0,0,0-.52-2.49,3.82,3.82,0,0,0-1.47-2.14,5.26,5.26,0,0,0-5-.17,4.19,4.19,0,0,0-1.47,1.29,5.85,5.85,0,0,0-.77,2,16.3,16.3,0,0,0-.35,2.19q-.07.94-.07,2.24v40.56q0,1.29.07,2.26a13.75,13.75,0,0,0,.37,2.19,6.51,6.51,0,0,0,.8,2,3.87,3.87,0,0,0,1.47,1.29,4.69,4.69,0,0,0,2.26.52,4.63,4.63,0,0,0,2.68-.72,3.82,3.82,0,0,0,1.47-2.16,13.73,13.73,0,0,0,.52-2.51q.1-1.07.1-2.86V473.9H356v7.36a39,39,0,0,1-.72,7.83,23.23,23.23,0,0,1-2.46,6.64,16.8,16.8,0,0,1-4.5,5.24,21.2,21.2,0,0,1-7,3.36A35.55,35.55,0,0,1,331.39,505.56Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M366.08,504.27V421.85h20.13v32.61H396.5V421.85h20.18v82.41H396.5V468.93H386.21v35.34Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M425.43,504.27l15-82.41h23.46l14.81,82.41H460.23l-2.58-16.45H447l-2.49,16.45Zm23.11-28.08H456l-3.73-30.77Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M487,504.27V421.85h20.18V490.8h18.34v13.47Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M533.4,504.27V421.85h20.18V490.8h18.34v13.47Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M579.82,504.27V421.85h38.57v13.52H600v18.39h13.72v14H600v23h18.59v13.47Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M626.59,504.27V421.85h14.32L656,457.15V421.85h16.65v82.41H658.85l-15-38.77v38.77Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M706.67,505.56a26.31,26.31,0,0,1-11-2.09,16.59,16.59,0,0,1-7.16-6,27.39,27.39,0,0,1-3.75-8.95,51.39,51.39,0,0,1-1.17-11.58V449.19a47.39,47.39,0,0,1,1.32-11.81,25.08,25.08,0,0,1,4.22-8.95,18.11,18.11,0,0,1,7.93-5.87,32.45,32.45,0,0,1,12.08-2,36.52,36.52,0,0,1,9.92,1.22,21.5,21.5,0,0,1,7.08,3.3,17.06,17.06,0,0,1,4.57,5.19,22.54,22.54,0,0,1,2.54,6.64,37.84,37.84,0,0,1,.75,7.8v5.92H714.18v-7.9q0-1.39,0-2.26a16.37,16.37,0,0,0-.3-2.14,5.66,5.66,0,0,0-.72-2,4.14,4.14,0,0,0-1.42-1.27,4.49,4.49,0,0,0-2.29-.55,4.74,4.74,0,0,0-2.73.75,4.08,4.08,0,0,0-1.57,2.11,12.59,12.59,0,0,0-.62,2.58,23.81,23.81,0,0,0-.15,2.86v40.41a15.38,15.38,0,0,0,.25,2.86,11.91,11.91,0,0,0,.82,2.61,4.69,4.69,0,0,0,1.74,2.06,5,5,0,0,0,2.81.77,4.87,4.87,0,0,0,3.55-1.39,6.88,6.88,0,0,0,1.89-3.16,14.67,14.67,0,0,0,.47-3.8V472.46h-6.61V460.87H734v43.39H720.54l-1-6Q715.92,505.56,706.67,505.56Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                  <path d="M745.19,504.27V421.85h38.57v13.52H765.37v18.39h13.72v14H765.37v23H784v13.47Z" transform="translate(-305.89 -149.19)" style={{fill: '#6d6e71'}} />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
      </div>
      <div className="svg-wrapper">
      <svg id="person" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 945.05 430.96">
        <title>TheWellbeingChallenge2</title>
        <g style={{isolation: 'isolate'}}>
          <g id="logo">
            <g>
              <circle id="head" cx="304.84" cy="226.77" r="41.5" transform="translate(-112.49 280.8) rotate(-75.68)" style={{fill: '#00abb5', opacity: '0.8300000000000001', mixBlendMode: 'multiply'}} />
              <path id="body" d="M252.46,387.54c-31.78,34.2-70.14,47.13-85.66,28.87s-2.35-60.79,29.44-95,70.14-47.13,85.66-28.87S284.25,353.33,252.46,387.54Z" transform="translate(-122.23 -185.26)" style={{fill: '#23826a', opacity: '0.8300000000000001', mixBlendMode: 'multiply'}} />
              <path id="legs" d="M252,391.32c40,26.7,64.56,65.62,54.86,86.93s-50,17-90-9.74S152.28,402.89,162,381.57,212,364.62,252,391.32Z" transform="translate(-122.23 -185.26)" style={{fill: '#e76c24', opacity: '0.8300000000000001', mixBlendMode: 'multiply'}} />
              <path id="shin" d="M267.66,548.2c-34.6,35.77-74.1,50.56-88.22,33s2.47-60.72,37.07-96.49,74.1-50.56,88.22-33S302.26,512.43,267.66,548.2Z" transform="translate(-122.23 -185.26)" style={{fill: '#ed1378', opacity: '0.8300000000000001', mixBlendMode: 'multiply'}} />
              <path id="hands" d="M184.87,330.59c44.23,15.16,89,10.13,100-11.23s-16-51-60.19-66.13-89-10.13-100,11.23S140.64,315.43,184.87,330.59Z" transform="translate(-122.23 -185.26)" style={{fill: '#442062', opacity: '0.8300000000000001', mixBlendMode: 'multiply'}} />
            </g>
          </g>
        </g>
      </svg>
      </div>
  </div>


)

}

export default AnimatedLogo;