import React, { useState, useEffect, useContext } from 'react';
import Formsy from 'formsy-react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import FormsyElement from '../../../HOC/FormsyElement/FormsyElement';
import { Password } from 'primereact/password';
import TitleBar from '../../../components/TitleBar/TitleBar';
import superApi from '../../../Api/Api';
import NotFound from '../../../components/NotFound/NotFound';
import { AuthContext } from '../../../Context/AuthContext';
import { Redirect } from 'react-router-dom';
import './Register.css';
import AnimatedLogo from '../../../components/Overlay/AnimatedLogoNew/AnimatedLogo';

const Register = (props) => {

    const [validRoute, setValidRoute] = useState(false);
    const [company, setCompany] = useState({ company: 'Not Found' });
    const [checking, setChecking] = useState(true)

    const auth = useContext(AuthContext);
    
    const tz_offset = () => {
        const d = new Date();
        return d.getTimezoneOffset();
    }
    useEffect(() => {
        // Verify company slug
        superApi.get('/get_company?slug=' + props.match.params.company)
            .then((response) => {
                setValidRoute(true);
                setCompany(response.data);
                setChecking(false)
            })
            .catch((error) => {
                setValidRoute(false);
                setChecking(false)
            })
    }, [])

    if (checking) {
        return <p>Please wait</p>
    }
    if (validRoute && !auth.authenticated) {
        return (
            <React.Fragment>
                {/* <TitleBar items={false} title={company.company.company}/> */}
                <div className="Register">
                    <div className="logo-wrapper">
                        <AnimatedLogo />
                    </div>
                    <div className="form-wrapper">
                        <Formsy onValidSubmit={(model) => props.auth.registerSubmit(model, auth)}
                            onValid={() => props.auth.enableButton()}
                            onInvalid={() => props.auth.disableButton()}>

                            <FormsyElement
                                name="username"
                                value={props.auth.userValue}
                                validations="isEmail"
                                validationError="Please enter a valid Email"
                                required>
                                <InputText
                                    value={props.auth.userValue}
                                    onChange={(e) => props.auth.setUserValueHandler(e.target.value)}
                                    placeholder="email@example.com"
                                    autoCapitalize="none"
                                />
                            </FormsyElement>

                            <FormsyElement
                                name="password"
                                value={props.auth.passValue}
                                validations="minLength:6"
                                validationError="Password too short"
                                required>
                                <Password
                                    value={props.auth.passValue}
                                    onChange={(e) => props.auth.setPassValueHandler(e.target.value)}
                                    placeholder="Password"
                                    feedback={false}
                                />
                            </FormsyElement>
                            <FormsyElement
                                name="password2"
                                value={props.auth.pass2Value}
                                validations={{
                                    matchesPasswordField: (values, value) => {
                                        return value === props.auth.passValue ? true : 'Password does not match';
                                    }
                                }}
                                validationError="Password does not match"
                                required>
                                <Password
                                    value={props.auth.pass2Value}
                                    onChange={(e) => props.auth.setPass2ValueHandler(e.target.value)}
                                    placeholder="Confirm Password"
                                    feedback={false}
                                />
                            </FormsyElement>
                            <FormsyElement
                                name="company"
                                value={props.match.params.company}>
                                <input type="hidden" name="company" value={props.match.params.company} />
                            </FormsyElement>
                            <FormsyElement
                                name="tz_offset"
                                value={tz_offset()}>
                                <input type="hidden" name="tz_offset" value={tz_offset()} />
                            </FormsyElement>
                            <Button label="sign up" type="submit" disabled={!props.auth.canSubmit} />
                            <div class="orLogIn"><a href="/login">Already signed up?</a></div>
                        </Formsy>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    else if (!auth.authenticated) {
        return (
            <NotFound />
        )
    }

    else {
        return <Redirect to="/" />
    }
}

export default Register;